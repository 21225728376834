import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations/lib/';
import FilmDataService from "../services/FilmService";
import ReactMarkdown from 'react-markdown'
import IFilmData from '../types/Film';
import { HeaderImageContext } from '../context/HeaderImageContext'
import { placeholderImageMobileContentHeader, placeholderImageDesktopContentHeader } from "../config.json";
import { isMobileView } from '../utils/windowSizeHelper';
//https://react-animations.herokuapp.com/

const AnimatedHover = styled.div`
&:hover {
  cursor:pointer;
  @media only screen and (min-width: 1080px){
  .film-title{
    animation: 1s ${keyframes(fadeIn)};
    letter-spacing:5x;
    }
  }
}
`;

const FilmsList: React.FC = () => {
  const [films, setFilms] = useState<Array<IFilmData>>([]);
  const { setHeaderImage } = useContext(HeaderImageContext)
  const [isMobile, setIsMobile] = useState<boolean>(isMobileView());
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const image = { name: 'films', url: placeholderImageDesktopContentHeader }
    setHeaderImage(image);
    retrieveFilms();
  }, [setHeaderImage]);

  const retrieveFilms = () => {
    FilmDataService.getAll()
      .then((response: any) => {
        const films = response.data.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
        setFilms(films);
      })
      .catch((e: Error) => {
        console.log('error fetching films');
        console.log(e);
      });
  };

  const gotoFilm = (id: string) => {
    navigate(`/film/${id}`, { replace: false });
  };

  return (
    <>

  <div className="content-header"  style={{ backgroundImage: isMobile ? 'url(' + placeholderImageMobileContentHeader + ')' : 'none' }} >
      <h2>FILMS</h2>
   
     <div className="film-list-container">
      {films &&
        films.map((film, index) => (
          <AnimatedHover  key={film.id.toString()}>
            <div className="film-item" onClick={() => gotoFilm(film.id || '1')}>
              <div className="film-title" >{film.attributes.title}</div>
              <div className="film-credits" ><ReactMarkdown children={film.attributes.subtitle || ''} /> </div>
            </div>
          </AnimatedHover>
        ))}
    </div>
    </div>
    </>
  );
};

export default FilmsList;
