import React, { useEffect, useContext,useState } from "react";
import { HeaderImageContext } from '../context/HeaderImageContext'
import { isMobileView } from '../utils/windowSizeHelper';

// import renderHTML from "react-render-html"
const Home: React.FC = () => {

  // const [currentPage, setCurrentPage] = useState<IPageData>();
  const { setHeaderImage } = useContext(HeaderImageContext);
  const [isMobile, setIsMobile] = useState<boolean>(isMobileView());

  const videoURL = 'https://s3.eu-west-1.amazonaws.com/assets.muriel-dansembourg.com/videos/goodnight.shortclip.02.mp4'
  const videoURLMobile = 'https://s3.eu-west-1.amazonaws.com/assets.muriel-dansembourg.com/videos/bg-cropped-goodnight-720p.mp4'


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const image = { name: 'home', url: 'url' }
    setHeaderImage(image);
  }, [setHeaderImage]);
  return (
    <div>
      {isMobile && (
      <>  
      {/* <img className="homeImage" alt="homeImage" /> */}
      <video   id="video-background-mobile" className="homeImage2" preload="false" muted loop autoPlay playsInline>
        <source
          src={videoURLMobile} 
          type="video/mp4"
        />
      </video>
      </>
      )}
      {!isMobile && 
      (<> 
        <video   id="video-background" muted loop autoPlay>
        <source
          src={videoURL} 
          type="video/mp4"
        />
      </video>
      
        </>)}
   
    </div>
  );
};

export default Home;
