import React, { useContext, useState, useEffect } from "react";
import { HeaderImageContext } from '../context/HeaderImageContext';
import { placeholderImageDesktopContentHeader } from "../config.json";
import { isMobileView } from '../utils/windowSizeHelper'; // Import the helper

const Header: React.FC = () => {
  const { headerImage } = useContext(HeaderImageContext);
  const [isMobile, setIsMobile] = useState<boolean>(isMobileView());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="header"
      id="main-header"
      style={{
        backgroundImage: !isMobile
          ? `url(${headerImage.url || placeholderImageDesktopContentHeader})`
          : 'none',
      }}
    >
      {/* Add any other content for your header here */}
    </div>
  );
};

export default Header;
