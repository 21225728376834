import React, { useEffect, useContext } from "react";
import { HeaderImageContext } from '../context/HeaderImageContext'

const ErrorPage: React.FC = () => {
  const { setHeaderImage } = useContext(HeaderImageContext)
  useEffect(() => {
    const image = { name: ``, url: 'https://mus-assets.s3.eu-west-1.amazonaws.com/large_s16_f2f644fc5b_77a6473e72.png?1717258057952' }
    setHeaderImage(image);
  }, []);


  return (
    <>
      <div className="content-header"   >
        <div className='generic-content' style={{ fontSize: '32px', fontWeight:"bold" }}>
          Oops
        </div>
        <div className='generic-content'>
          We can't seem to find the page you're looking for.
        </div>
        <div className='generic-content' style={{ fontSize: '13px' }}>
          Error 404
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
