import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { HeaderImageContext } from './context/HeaderImageContext'
import Header from "./components/Header"
import Home from "./components/Home";
import FilmsList from "./components/FilmsList";
import ErrorPage from "./components/ErrorPage"
import Film from "./components/Film"
import GenericPage from "./components/GenericPage"
import { isMobileView } from './utils/windowSizeHelper';

const App: React.FC = () => {

  const { pathname } = useLocation();
  const [headerImage, setHeaderImage] = useState({ name: '', url: '' });
  const [homeLinkStyle, setHomeLinkStyle] = useState('');
  const [homeLinkStyleMobile, setHomeLinkStyleMobile] = useState('');
  const [navbarTop, setNavbarTop] = useState('');
  const [isMobile, setIsMobile] = useState<boolean>(isMobileView());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setHomeLinkStyle(pathname === '/' ? 'home' : '');
    setHomeLinkStyleMobile(pathname === '/' ? 'navbar-inner navbar-dark' : '');
    setNavbarTop(pathname === '/' ? 'fixed-top' : '');
  }, [pathname]);

  return (
    <>
      <HeaderImageContext.Provider value={{ headerImage, setHeaderImage }}>
        {pathname !== '/' && (
          <Container className="p-0 d-none d-md-block" fluid >
            <Header  />
          </Container>)}
        <Navbar className={`${navbarTop}`} expand="lg" >
          <Container>
            <Navbar.Brand className={` p-r-5 logo ${homeLinkStyle}`} href="/">MURIEL D'ANSEMBOURG FILMS</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className={`navbar ${homeLinkStyleMobile}`} />
            <Navbar.Collapse id="navbar-nav">
              <Nav className={`navbar-nav ${homeLinkStyle} ml-auto`}>
                <Nav.Link href="/about">About</Nav.Link>
                <Nav.Link href="/films">Films</Nav.Link>
                <Nav.Link href="/news">News</Nav.Link>
                <Nav.Link href="/interviews">Interviews</Nav.Link>
                <Nav.Link href="/reviews">Reviews</Nav.Link>
                <Nav.Link href="/contact">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div  className={isMobile?'container-fluid p-0': 'container'}    id={"main-header-mobile"}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/films" element={<FilmsList />} />
            <Route path="/film/:id" element={<Film />} />
            {/* <Route path="/page/:id" element={<GenericPage />} /> */}
            <Route path="/about" element={<GenericPage id={1}  />} />
            <Route path="/contact" element={<GenericPage id={2} />} />
            <Route path="/interviews" element={<GenericPage id={3} />} />
            <Route path="/news" element={<GenericPage id={4} />} />
            <Route path="/reviews" element={<GenericPage id={5} />} />
            <Route path="/page/*" element={<Navigate to ="/" state={{status:301}}/>}/>
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          {pathname !== '/' && (  <div><p className=" content-header footer small text-left">© 2024 Muriel d'Ansembourg All rights reserved.</p></div>)}
        </div>
      </HeaderImageContext.Provider>
      <div className="footer"></div>
    </>
  );
}

export default App;
