
import { useContext, createContext } from 'react';

export interface HeaderImageInterface {
  name: string;
  url: string;
};

export type HeaderImageContextType = {
  headerImage: HeaderImageInterface;
  setHeaderImage: (HeaderImage: HeaderImageInterface) => void;
};

export const HeaderImageContext = createContext<HeaderImageContextType>({ headerImage: {name:'',url:''}, setHeaderImage: headerImage => console.warn('no image provider')});

// export const HeaderImageContext = createContext<HeaderImageContextType>(
//   { headerImage: { name: '', url: '' }, setHeaderImage: () => console.warn('no image provider') }
//);

export const useHeaderImage = () => useContext(HeaderImageContext);
