import http from "../http-common-aws";
import IFilmData from "../types/Film";

const getAll = () => {
  return http.get<Array<IFilmData>>(`/films.json?ts=${Math.floor(Date.now() / 1000)}`);
};

const get = (id: any) => {
  return http.get<IFilmData>(`/films/${id}`);
};

const findByTitle = (title: string) => {
  return http.get<Array<IFilmData>>(`/films=${title}`);
}; 
 

const FilmService = {
  getAll,
  get,
  findByTitle,
};

export default FilmService;
