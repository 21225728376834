import axios from "axios";
import config from "./config.json";

const {  awsApi } = config;
let apiURL;

if (process.env.NODE_ENV === 'development') {
  apiURL = awsApi; 
} else {
  apiURL = awsApi;
}
 
export default axios.create({
  baseURL: apiURL,
  headers: {
    "Content-type": "application/json"
  }
});

