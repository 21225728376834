import http from "../http-common-aws";
import IPageData from "../types/Page";

const getAll = () => {
  return http.get<Array<IPageData>>("/pages.json");
};

const get = (id: any) => {
  return http.get<IPageData>(`/pages/${id}`);
};

const findByName = (name: string) => {
  return http.get<Array<IPageData>>(`/pages=${name}`);
};

const PageService = {
  getAll,
  get,
  findByName,
};

export default PageService;
