import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, Link , useNavigate} from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'
import FilmDataService from "../services/FilmService";
import IFilmData from "../types/Film";
import { HeaderImageContext } from '../context/HeaderImageContext'
import { BsFillArrowLeftCircleFill, BsArrowsFullscreen, BsFillPlayCircleFill } from "react-icons/bs";
import ReactPlayer from 'react-player'
import { isMobileView } from '../utils/windowSizeHelper'; // Import the helper
import { placeholderImageMobileContentHeader } from "../config.json";
// import screenfull from 'screenfull'


const Film: React.FC = () => {
    const { id } = useParams();
    // const player = useRef(null);
    // const ref = React.useRef<ReactPlayer>(null);
    const initialFilmState = {
        id: null,
        attributes: {
            title: "",
            subtitle: "",
            credits: "",
            imdb: "",
            director_notes: "",
            synopsis: "",
            awards: "",
            trailer: "",
            image_mobile_url: "",
            image_desktop_url: ""
        }
    }

    const { setHeaderImage } = useContext(HeaderImageContext)
    const [currentFilm, setCurrentFilm] = useState<IFilmData>(initialFilmState);
    const [playing, setPlaying] = useState(false);
    const [isInTrailerMode, setIsInTrailerMode] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(isMobileView());
    const navigate = useNavigate();

    const handleSelect = (key) => {
        let image;
        if (key === 'trailer') {
            image = { name: ``, url: `${currentFilm.attributes.image_desktop_url} dark-mode` }
            setHeaderImage(image);
            setIsInTrailerMode(true);
        } else {
            setIsInTrailerMode(false);
        }
    }

    const handleFullscreen = (event) => {
        // screenfull.request(player.current.wrapper);
    }

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(isMobileView());
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    useEffect(() => {
        if (id) {
            FilmDataService.getAll()
                .then((response: any) => {
                    const film = response.data.data.filter((item: { id: number; }) => {
                        return item.id === Number(id)
                    })
                    
                    if (film.length===0) {
                        navigate('/error');  // Navigate to the error page if no film matches the id
                        return;  // Stop further execution
                    }
                    return film;
                }).then((data: any) => {
                    if (!data[0].attributes.image_mobile_url) {
                        data[0].attributes.image_mobile_url = placeholderImageMobileContentHeader
                    }
                    //TODO: remove cache buster when images finalized
                    const image = { name: `film-${id}`, url: data[0].attributes.image_desktop_url + '?' + new Date().getTime() }
                    setHeaderImage(image);
                    setCurrentFilm(data[0]);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }

    }, [id, setHeaderImage]);

    return (
        <>
            <div className={`content-header `}>
                <div className={` header-film ${isInTrailerMode ? 'trailer-dark-mode-mobile' : ''}`} style={{ backgroundImage: isMobile ? 'url(' + currentFilm.attributes.image_mobile_url + '?' + new Date().getTime() + ')' : 'none' }} >
                    <h2 className='film-title'>{currentFilm.attributes.title}</h2>
                </div>
                <Tabs onSelect={handleSelect} defaultActiveKey="synopsis" className="tab-list">

                    <Tab className="my-tab" eventKey="synopsis" title="Synopsis">
                        <ReactMarkdown children={currentFilm.attributes.synopsis || ''} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} />
                    </Tab>
                    {currentFilm.attributes.director_notes && (
                        <Tab className="my-tab" eventKey="director_notes" title="Director's Notes">
                            <ReactMarkdown children={currentFilm.attributes.director_notes} />
                        </Tab>
                    )}
                    {currentFilm.attributes.trailer && (
                        <Tab className="my-tab trailers" eventKey="trailer" title="Trailer">
                            {currentFilm.attributes.trailer.split(",").map((url) => (
                                <>
                                    <div className="player-wrapper"><ReactPlayer
                                        className="my-trailer" playing={playing} controls={true} url={url} />

                                    </div>
                                    {/* <div className="row no-gutters">
                                        <BsFillPlayCircleFill onClick={() => setPlaying(!playing)} style={{ display: !playing ? 'none' : 'block' }} className="player-icon mr-3" />
                                        <BsFillPlayCircleFill onClick={() => setPlaying(!playing)} style={{ display: !playing ? 'block' : 'none' }} className="player-icon mr-3" />
                                        <BsArrowsFullscreen onClick={() => handleFullscreen} className="player-icon " />
                                    </div> */}
                                </>
                            ))}
                        </Tab>
                    )}
                    <Tab className="my-tab" eventKey="credits" title="Credits">
                        <ReactMarkdown children={
                            currentFilm.attributes.imdb
                                ? `${currentFilm.attributes.credits}\n\n**[IMDb:](${currentFilm.attributes.imdb})** [${currentFilm.attributes.imdb}](${currentFilm.attributes.imdb})`
                                : currentFilm.attributes.credits || ''
                        } />

                    </Tab>
                    {currentFilm.attributes.awards && (
                        <Tab className="my-tab" eventKey="awards" title="Awards">
                            <ReactMarkdown children={currentFilm.attributes.awards} />
                        </Tab>
                    )}
                </Tabs>
                <div className="back-to-film"><Link to="/films" style={{ textDecoration: 'none' }}> <BsFillArrowLeftCircleFill color={'#272622d5'} style={{ marginTop: -4 }} />  <span className='back-to-films-text'> Back to films </span></Link></div>
            </div>
        </>
    );
};

export default Film;
