import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'
import PageDataService from "../services/PageService";
import IPageData from "../types/Page";
import { HeaderImageContext } from '../context/HeaderImageContext'
import * as Scroll from 'react-scroll';
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { isMobileView } from '../utils/windowSizeHelper'; // Import the helper
import { placeholderImageMobileContentHeader } from "../config.json";
interface GenericPageProps {
  id: Number
};

const GenericPage: React.FC<GenericPageProps> = (props) => {

  const { pathname } = useLocation();
  const { id } = props;
  const [currentPage, setCurrentPage] = useState<IPageData>();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileView());
  const { setHeaderImage } = useContext(HeaderImageContext)
  let Link = Scroll.Link;

  useEffect(() => {
    if (id) {
      PageDataService.getAll()
        .then((response: any) => {
          const film = response.data.data.filter((item) => {
            return item.id === id;
          })
          return film;
        }).then((data: any) => {
          if (!data[0].attributes.image_mobile_url) {
            data[0].attributes.image_mobile_url = placeholderImageMobileContentHeader + '?' + new Date().getTime();
          }
           //TODO: remove cache buster when images finalized
          const image = { name: `page-${id}`, url: data[0].attributes.image_desktop_url + '?' + new Date().getTime() }
          setHeaderImage(image);
          setCurrentPage(data[0]);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  }, [setHeaderImage, id]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      {currentPage && (
        <div className="content-header " style={{ backgroundImage: isMobile ? 'url(' + currentPage?.attributes.image_mobile_url + '?' + new Date().getTime() + ')' : 'none' }}   >
          <h2>{currentPage?.attributes.title}</h2>
          <div className='generic-content'>
            <ReactMarkdown children={currentPage?.attributes.description || ''} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
          </div>
          <div style={{ "marginTop": "20px", "height": "50px" }}>
            {!pathname.includes('/contact') && (
              <Link style={{ "color": "black", "textDecoration": "none" }} to={isMobile ? 'main-header-mobile' : 'main-header'} onClick={(e) => { e.stopPropagation() }} spy={true} smooth={true} duration={500}>
                <BsFillArrowUpCircleFill color={'#272622d5'} />  <span className='back-up'> Back up </span>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GenericPage;
